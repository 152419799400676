import React from "react";
import './style.css';


const EventArea = () => {
    return (
        <>
            <div className="event-area">
                <h1>HANGOUT AREA</h1>
            </div>
        </>
    );
}

export default EventArea;
